
import { defineComponent, computed, PropType, onUpdated, ref } from "vue";
import { HeroData } from "@/store/app.config.ts";
import { gsap } from "gsap";

export default defineComponent({
  name: "PageHeroInner",
  props: {
    heroData: {
      type: Object as PropType<HeroData>,
      required: true
    }
  },
  setup(props) {
    const hData = computed(() => props.heroData);
    const content = ref<HTMLElement>();
    /**
     * 更新
     */
    onUpdated(() => {
      if (content.value != undefined) {
        gsap.set(content.value, { alpha: 0 });
        gsap.to(content.value, {
          delay: 1,
          duration: 1,
          alpha: 1,
          ease: "power2.in"
        });
      }
    });
    return { hData, content };
  }
});
