
import { defineComponent, computed, PropType } from "vue";
import { CarouselAboutData } from "@/store/app.config.ts";
import store from "@/store";
import { DispatchType, ModalId } from "@/store/app.config.ts";

export default defineComponent({
  name: "PageModal",
  props: {
    carouselData: {
      type: Object as PropType<CarouselAboutData[]>,
      required: true
    }
  },
  setup(props) {
    const cData = computed(() => props.carouselData);
    const cIndex = computed(() => store.getters.modalId);
    const isModalOpen = computed(() => store.getters.isModalOpen);
    /**
     * モーダル
     */
    const onCloseModal = () => {
      store.dispatch(DispatchType.MODAL_CLOSE);
      store.dispatch(DispatchType.SET_MODAL, ModalId.NONE);
    };

    return {
      cData,
      cIndex,
      isModalOpen,
      onCloseModal
    };
  }
});
