
import { defineComponent, reactive, ref, toRefs } from "vue";
import axios from "axios";
import { Tabs, Tab, TabPanels, TabPanel } from "vue3-tabs";
import { getCategoryNewsData, getDataDateTime, getCategoryStr, getCategoryNum } from "@/store/app.utils.ts";
import { NewsData } from "@/store/app.config.ts";

const tabs = ["All", "Book", "Author"];

export default defineComponent({
  name: "Top",
  setup() {
    const newsData = ref([]);
    const state = reactive({
      selectedTab: tabs[0]
    });

    axios
      .get("/json/news.json")
      .then(response => {
        newsData.value = response.data.response;
      })
      .catch(error => {
        console.log("🚀 ~ file: Top.vue ~ line 24 ~ setup ~ error", error);
      });
    return {
      newsData,
      tabs,
      ...toRefs(state)
    };
  },
  methods: {
    getDateTime(dateStr: string): string {
      return getDataDateTime(dateStr);
    },
    getCategoryNewsData(data: NewsData[], category: number): NewsData[] {
      return getCategoryNewsData(data, category);
    },
    getCategoryName(catNum: number): string {
      return getCategoryStr(catNum);
    },
    getCategoryNumber(catString: string): number {
      return getCategoryNum(catString);
    }
  },
  components: {
    Tabs,
    Tab,
    TabPanels,
    TabPanel
  }
});
