
import { defineComponent } from "vue";
import PageBreadcrumbs from "@/components/pages/PageBreadcrumbs.vue";
import PageAccordion from "@/components/pages/PageAccordion.vue";
import { AppData } from "@/store/app.data.ts";

export default defineComponent({
  name: "Faq",
  data() {
    return {
      faqData: AppData.faqDataList
    };
  },
  components: {
    PageBreadcrumbs,
    PageAccordion
  }
});
