import { Credentials } from "./types/credentials";

export const credentials: Credentials = {
  config: {
    apiKey: "AIzaSyAev7tiV9kc65hkfIO5osstVk_vccjGv8g",
    authDomain: "sample-app-10441.firebaseapp.com",
    projectId: "sample-app-10441",
    storageBucket: "sample-app-10441.appspot.com",
    databaseURL: "",
    messagingSenderId: "663279292805",
    appId: "1:663279292805:web:5fa589312d33fe60d534ec",
    measurementId: "G-MT435BHTCH"
  }
};
