
import { defineComponent, computed } from "vue";
import AppHeader from "@/components/AppHeader.vue";
import AppHero from "@/components/AppHero.vue";
import AppFooter from "@/components/AppFooter.vue";
import store from "@/store";
import { Page } from "@/router/index.ts";
import router from "@/router";
import "normalize.css";

export default defineComponent({
  setup() {
    const url = location.href;
    if (url.indexOf("http:") >= 0) {
      const newUrl = url.replace("http:", "https:"); // 書き換え方法の修正
      // リダイレクト
      location.replace(newUrl);
    }

    const nowPage = computed(() => store.getters.nowPage);
    const isTop = computed(() => router.currentRoute.value.name === Page.TOP);

    return {
      nowPage,
      isTop
    };
  },
  components: {
    AppHeader,
    AppHero,
    AppFooter
  }
});
