
import { defineComponent } from "vue";
import { menuRoutes } from "@/router/index.ts";

export default defineComponent({
  name: "PageBreadcrumbs",
  props: {
    label: {
      type: String,
      required: true
    }
  },
  setup(props) {
    let routeData;
    for (const data of menuRoutes) {
      if (data.name === props.label) routeData = data;
    }
    const paths: string[] = ["/"];
    const names: string[] = ["Top"];
    if (routeData) {
      paths.push(routeData.path);
      names.push(props.label);
    }
    return { paths, names };
  }
});
