
import { defineComponent, computed, onUpdated } from "vue";
import AppMenuTrigger from "@/components/header/AppMenuTrigger.vue";
import AppMenu from "@/components/AppMenu.vue";
import store from "@/store";
import anime from "animejs";

export default defineComponent({
  name: "AppHeader",
  setup() {
    const isMenuOpen = computed(() => store.getters.isMenuOpen);

    console.log(">>AppHeader >setup | isMenuOpen : " + isMenuOpen.value);

    onUpdated(() => {
      anime({
        targets: ".AppHeader__bgMorph",
        d: [
          {
            value: "M 7.7423617,6.5524041 C 0.14213171,13.241204 -0.28352929, 25.218399 6.5488487,32.446038 13.237649,40.04627 24.578354, 40.568996 32.442483,33.639553 39.813699,26.434583 40.216105, 14.96193 33.635997,7.7459191 26.935569,0.39795815 15.101951, -0.40029585 7.7423617,6.5524041 Z"
          },
          {
            value: "M 19.499615,1.5030295 C 15.341558,11.017856 5.4243459, 31.217087 1.2037966,39.132841 10.361482,39.005187 29.91693, 39.467197 38.833611,39.035804 34.978274,30.942616 24.624859, 11.418574 20.165144,1.5845618 18.73688,1.6665173 20.913606, 1.4728946 19.499615,1.5030295 Z"
          }
        ],
        easing: isMenuOpen.value ? "easeOutQuad" : "easeInQuad",
        duration: 400
      });
    });

    return {
      isMenuOpen
    };
  },
  components: {
    AppMenuTrigger,
    AppMenu
  }
});
