
import { defineComponent } from "vue";
import PageBreadcrumbs from "@/components/pages/PageBreadcrumbs.vue";
import PageModal from "@/components/pages/PageModal.vue";
import { AppData } from "@/store/app.data.ts";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { DispatchType } from "@/store/app.config.ts";
import store from "@/store";
import "swiper/swiper.scss";

SwiperCore.use([Navigation]);

export default defineComponent({
  name: "About",
  data() {
    return {
      selectChapterIndexList: [0, 0, 0, 0, 0],
      chapterData: AppData.chapterDataList,
      chapterDetailData2: AppData.chapterDetailDataList2,
      carouselAboutData: AppData.carouselAboutDataList,
      swiperCtrl: { realIndex: 0 },
      nowCarouselNum: 0,
      maxCarouselNum: AppData.chapterDetailDataList2.length
    };
  },
  methods: {
    selectChapter(chapterIndex: number, detailIndex: number) {
      this.selectChapterIndexList[chapterIndex] = detailIndex;
    },
    onSwiper(e: SwiperCore) {
      console.log(e);
      this.swiperCtrl = e;
    },
    onSlideChange() {
      this.nowCarouselNum = this.swiperCtrl.realIndex;
    },
    onOpenModal(id: number) {
      store.dispatch(DispatchType.MODAL_OPEN);
      store.dispatch(DispatchType.SET_MODAL, id);
    },
    onClickCarouselPrev() {
      this.nowCarouselNum--;
      if (this.nowCarouselNum < 0) this.nowCarouselNum = this.maxCarouselNum - 1;
    },
    onClickCarouselNext() {
      this.nowCarouselNum++;
      if (this.nowCarouselNum > this.maxCarouselNum - 1) this.nowCarouselNum = 0;
    }
  },
  components: {
    PageBreadcrumbs,
    PageModal,
    Swiper,
    SwiperSlide
  }
});
