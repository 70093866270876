
import { defineComponent } from "vue";
import AppMenuLink from "@/components/AppMenuLink.vue";
import { menuRoutes } from "@/router/index.ts";

export default defineComponent({
  name: "AppMenu",
  data() {
    return {
      href: "",
      pages: menuRoutes
    };
  },
  components: {
    AppMenuLink
  }
});
