
import { defineComponent } from "vue";
import { AppData } from "@/store/app.data.ts";

export default defineComponent({
  name: "PageBanner",
  data() {
    return {
      bannerData: AppData.bannerDataList
    };
  }
});
