
import { defineComponent, reactive, toRefs, onMounted, ref } from "vue";

export default defineComponent({
  name: "PageAccordion",
  setup() {
    const state = reactive({
      height: "1em",
      isOpen: false
    });
    // <div class="head" ref="head">
    const head = ref<HTMLElement>();
    // <div class="body" ref="body">
    const body = ref<HTMLElement>();

    const open = () => {
      const headHeight: number = head.value?.offsetHeight || 0;
      const bodyHeight: number = body.value?.offsetHeight || 0;
      state.height = `${headHeight + bodyHeight}px`;
      state.isOpen = true;
    };

    const close = () => {
      state.height = `${head.value?.offsetHeight}px`;
      state.isOpen = false;
    };

    const toggle = () => {
      state.isOpen ? close() : open();
    };
    onMounted(() => {
      state.height = `${head.value?.offsetHeight}px`;
    });

    return {
      toggle,
      head,
      body,
      ...toRefs(state)
    };
  }
});
