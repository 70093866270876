
import { defineComponent } from "vue";
import store from "@/store";
import { DispatchType } from "@/store/app.config.ts";

export default defineComponent({
  name: "AppMenuLink",
  props: {
    href: {
      type: String,
      required: true,
      default: "/"
    }
  },
  methods: {
    onClickMenuClose() {
      store.dispatch(DispatchType.MENU_CLOSE);
    }
  }
});
