
import { defineComponent, onUpdated, onMounted, toRefs, reactive } from "vue";
import { getDataDateTime, findObjectByKey, getCategoryStr } from "@/store/app.utils.ts";
import { NewsData } from "@/store/app.config";
import axios from "axios";

export default defineComponent({
  name: "TopNews",
  props: ["id"],
  setup(props) {
    const state = reactive({
      detailDate: "",
      detailTitle: "",
      detailContent: "",
      detailCategory: 0
    });
    const getNewsDetailData = (detailId: number) => {
      axios
        .get("/json/news.json")
        .then(response => {
          const newsData: NewsData = findObjectByKey(response.data.response, "id" as keyof object, Number(detailId)) as NewsData;
          state.detailDate = newsData.date;
          state.detailTitle = newsData.title;
          state.detailContent = newsData.content;
          state.detailCategory = newsData.category;
        })
        .catch(error => {
          console.log("🚀 ~ file: TopNews.vue ~ line 30 ~ getNewsDetailData ~ error", error);
        });
    };
    onMounted(() => {
      getNewsDetailData(props.id);
    });
    onUpdated(() => {
      getNewsDetailData(props.id);
    });

    return {
      ...toRefs(state)
    };
  },
  methods: {
    getDateTime(dateStr: string): string {
      return getDataDateTime(dateStr);
    },
    getCategoryName(catNum: number): string {
      return getCategoryStr(catNum);
    }
  }
});
