
import { defineComponent } from "vue";
import PageBreadcrumbs from "@/components/pages/PageBreadcrumbs.vue";

export default defineComponent({
  name: "Author",
  components: {
    PageBreadcrumbs
  }
});
