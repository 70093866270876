
import store from "@/store";
import { DispatchType } from "@/store/app.config.ts";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppMenuTrigger",
  methods: {
    onClickMenuToggle() {
      store.dispatch(DispatchType.MENU_TOGGLE);
    }
  },
  setup() {
    store.dispatch(DispatchType.MENU_CLOSE);
  }
});
