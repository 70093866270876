
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import PageBreadcrumbs from "@/components/pages/PageBreadcrumbs.vue";
import firebase from "firebase";
import { FirebaseAuth } from "@/firebase/auth";

export default defineComponent({
  name: "ProductionNote",
  components: {
    PageBreadcrumbs
  },
  setup() {
    const state = reactive({
      loginStatus: false
    });
    onMounted(() => {
      FirebaseAuth.onAuthStateChanged(user => {
        if (user) {
          console.log("ログインしています。");
          state.loginStatus = true;
        } else {
          console.log("ログインしていません。");
          state.loginStatus = false;
        }
      });
    });
    const onSignInGoogle = () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      FirebaseAuth.signInWithPopup(provider)
        .then(() => {
          console.log("ログインしました。");
          state.loginStatus = true;
        })
        .catch(error => {
          const signinError = `
        サインインエラー
        エラーメッセージ： ${error.message}
        エラーコード: ${error.code}
        `;
          console.log(signinError);
        });
    };
    const onSignInFacebook = () => {
      const provider = new firebase.auth.FacebookAuthProvider();
      FirebaseAuth.signInWithPopup(provider)
        .then(() => {
          console.log("ログインしました。");
          state.loginStatus = true;
        })
        .catch(error => {
          const signinError = `
        サインインエラー
        エラーメッセージ： ${error.message}
        エラーコード: ${error.code}
        `;
          console.log(signinError);
        });
    };
    const onSignInTwitter = () => {
      const provider = new firebase.auth.TwitterAuthProvider();
      FirebaseAuth.signInWithPopup(provider)
        .then(result => {
          state.loginStatus = true;
          const user = result.user;
          console.log(">>onSignInTwitter | ログインしました。user", user);
        })
        .catch(error => {
          const signinError = `
      サインインエラー
      エラーメッセージ： ${error.message}
      エラーコード: ${error.code}
      `;
          console.log(signinError);
        });
    };
    const onSignOut = () => {
      FirebaseAuth.onAuthStateChanged(user => {
        FirebaseAuth.signOut()
          .then(() => {
            console.log("ログアウトしました");
            state.loginStatus = false;
            console.log(user);
            location.reload();
          })
          .catch(error => {
            console.log(`ログアウト時にエラーが発生しました (${error})`);
          });
      });
    };
    return {
      onSignInGoogle,
      onSignInFacebook,
      onSignInTwitter,
      onSignOut,
      ...toRefs(state)
    };
  }
});
